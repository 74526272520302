import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default class Testimonials extends Component {
  render() {
    return (
        <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          {/* <img src="/images/shirley.png" /> */}
          <div className="myCarousel">
            <h3>Ben Tapueluelu</h3>
            {/* <h4>Designer</h4> */}
            <p>Love the work and service they provide. Easy going very professional and very flexible with fitting in with my schedule. Well recommended!!
            </p>
          </div>
        </div>

        <div>
          {/* <img src="/images/daniel.png" /> */}
          <div className="myCarousel">
            <h3>Ihra Marie Soriano</h3>
            {/* <h4>Designer</h4> */}
            <p>
            Amazing!!! My car has never been so spotless smells like coconut and pineapple as well.. Guys have done an exceptional job for such a reasonable price on top of that. <br />
            Also accidentally left the door open but one of the guys came through and helped me jump start it.. Without no hesitation!!! Big ups for that thank you so much will definitely be back and recommend
            </p>
          </div>
        </div>

        {/* <div>
           <img src="/images/theo.png" /> 
          <div className="myCarousel">
            <h3>Theo Sorel</h3>
            <h4>Designer</h4>
            <p>
              I enjoy catching up with Fetch on my laptop, or on my phone when
              I'm on the go!
            </p>
          </div>
        </div> */}
      </Carousel>
    );
  }
}