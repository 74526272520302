import React from "react"
import "../scss/index.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../components/layout/layout"
import Header from "../components/layout/header"
import Hero from "../components/hero/hero"
import Double from "../components/layout/double"
import Quad from "../components/layout/quad"
import Footer from "../components/layout/footer"
import Icon1 from "../../static/icons/icon-car.svg"
import Icon2 from "../../static/icons/icon-chopper.svg"
import Icon3 from "../../static/icons/icon-yatch.svg"
import Icon4 from "../../static/icons/icon-caravan.svg"
import Image1 from "../../static/img/5-star-review.png"
import { Helmet } from "react-helmet"
import Testimonials from "../components/testimonials/testimonials";
// import FloatingNotice from "../components/notice/floating-notice";
import PriceBlock from "../components/price-block/priceBlock";
import ReCAPTCHA from "react-google-recaptcha"

export default function Home() {
  return <>
  <Helmet>
          <meta charSet="utf-8" />
          <title>Eco Wash Solutions | Anywhere Anytime We Come To You</title>
          <description>GTA wide there are millions of vehicles on the roads every day. They are getting dirty with daily driven. It is very important to clean and maintain them because this is your investment. Every vehicle should clean once a week or every fortnightly or must be every four weeks time. Also upholstery and carpet need to clean</description>
          <link rel="canonical" href="https://ecowashsholutions.ca" />
          <div id="fb-root"></div>
          <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v8.0" nonce="g9L9nfuY"></script>
        </Helmet>
  <Header />
  <Layout>
  {/* Hero Block */}
  <Hero pretitle="Modern Edquipment" title="Car Wash Solutions" description="Anywhere Anytime We Come to You" />
   {/* Intro Block */}
      {/* Icons / Services */}
      <Quad id="services" className="align--center">
    <div className="block-item a">
      <img className="icon-medium" src={Icon1} alt="Car Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Car</span> Grooming</h4>
    </div>
    <div className="block-item">
      <img className="icon-medium" src={Icon2} alt="Motorcycle Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Motorcycle</span> Grooming</h4>
  </div>
  <div className="block-item">
       <img className="icon-medium" src={Icon3} alt="Boat Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Boat</span> Grooming</h4>
  </div>
  <div className="block-item">
      <img className="icon-medium" src={Icon4} alt="RV / Motorhome Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>RV</span> Grooming</h4>
  </div>
   </Quad>
   <Double align="left" type="light">
   <div className="double-block two-third" id="about1">
      <h6 className="sub-heading">Eco Wash Solutions</h6>
      <h2 className="heading">Anywhere Anytime We Come to You</h2>
      {/* <h6 className="sub-heading sub-heading--basic">Eco Wash Solutions proudly introduces</h6> */}
      <p>Greater Toronto Area wide there are millions of vehicles on the roads every day. They are getting dirty with daily driven. It is very important to clean and maintain them because this is your investment. Every vehicle should clean once a week or every fortnightly or must be every four weeks time.
      Also upholstery and carpet need to clean twice a year if your car has leather seats then leather clean and condition three times in a year to protect from cracking. <br /><br />
      But we are so busy in our lifestyle we do not have time to clean . If we have time then we need to invest money and time to clean them.<br /><br />
      Premium brands like Lamborghini , Rolls Royce , Bentley, Ferrari, Mercedes, BMW, Audi, Jaguar, Land Rover all need a professional care.<br /><br />
      Eco Wash Solutions &amp; Detail provide you mobile car grooming service with no extra cost.</p>
      <AnchorLink to="/#contact" title="Contact Us" className="button button--large button--primary">
      Find out more..
      </AnchorLink>
      </div>
      <div className="double-block on-third">
        <img src={Image1} alt="Eco Wash Solutions" title="Eco Wash Solutions" lazyload="true" className="ratings-image" width="100%" />
      </div>
   </Double>   

    {/* About Block */}
    <div className="pricing-block container--dark container--centered" id="about">
    <div className="container container--narrow">
    <h2 className="heading align--center">About</h2>
    <h6 className="sub-heading sub-heading--basic align--center">What we do..</h6>
    <p>Eco Wash Solutions has been delivering results since opened in New Zealand in 2013 and now open in Greater Toronto Area. Our goal is to provide both a superior customer service experience and tremendous value for our customers.</p>
    <p>Eco Wash Solutions has good experience in Mobile Car Wash Service and we are the best mobile car groomer in Greater Toronto Area. Our valued customers provide us 5star review on our Facebook page. We are also passionate about exceeding customers expectations.</p>
    <p>Eco Wash Solutions and Detail services from Daily driven vehicle, Luxury , Luxury sports , Classic car to RV's, Boats and Motorcycles</p>
    <p>Eco Wash Solutions and Detail provide skilled and experienced car valet services for your car. We can easily fit in your schedule, wherever you are, in office or home.</p>
    <p>Eco Wash Solutions has highly competitive prices list below . You can choose one of them or if you not sure about any detail we are happy to assist you over the phone or fill up simple contact form below and we will get in touch.</p>
    </div>
    </div>
   {/* Pricing Block */} 
   <PriceBlock />
  
  {/* Testimonials - To be added in future */}
  <div className="pricing-block container--dark container--centered" id="testimonials">
     <div className="container">
     <h2 className="heading align--center">Testimonials</h2>
     <h6 className="sub-heading sub-heading--basic align--center">What our clients say...</h6>
     <Testimonials/>
     </div>
  </div>
   {/* Contact Block */}
   <Double align="right" type="light" id="contact">
     <div className="double-block">
       <h6  className="sub-heading">Get a Quote</h6>
       <h2 className="heading">Contact Us</h2>
      <p>For any enquiries regarding our services, please contact at details below or get in touch via our contact form.</p>
      <h4>Greater Toronto Area</h4>
      {/* <Button title="Find out More" size="large" type="primary" link="#" /> */}
      <p>P: <a className="styled-link" href="tel:905 701 3555">905 701 3555</a><br />
         {/* P: <a className="styled-link" href="tel:09 215 4117">09 215 4117</a><br /> */}
        E: <a className="styled-link" href="mailto:info@ecowashsolutions.a">info@ecowashsolutions.ca</a>
        </p>

        {/* <h4>Hamilton</h4>
      <p>P: <a className="styled-link" href="tel:0800 758 575"> 0800 758 575</a><br />
        M: <a className="styled-link" href="tel:021 275 8575">021 275 8575</a><br />
         E: <a className="styled-link" href="mailto:hamilton@ecocarwash.co.nz">hamilton@ecocarwash.co.nz</a>
        </p> */}
        
        <form method="post" data-netlify="true" data-netlify-recaptcha="true" name="contact" className="contact-form">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
          <label>
            <input type="text" name="name" id="name" placeholder="Name" />
          </label>
          <label>
            <input type="email" name="email" id="email" placeholder="Email" />
          </label>
          <label>
            <input type="text" name="phone" id="phone" placeholder="Phone Number" />
          </label>
          <label>
            <input type="text" name="subject" id="subject" placeholder="Subject" />
          </label>
            <p>Preferred mode of contact</p>
            <div className="inline-radio">
              <label>
                <input type="radio" name="contact-mode" id="radio1" value="email" />
                Email
              </label>
              <label>
                <input type="radio" name="contact-mode" id="radio2" value="phone" />
                Phone
              </label>
            </div>
           <label>
            <textarea name="message" id="message" rows="5" placeholder="Message" />
          </label>
          <ReCAPTCHA sitekey="6LfFTw4iAAAAAO5J5OzoETxZxNbhBvExdNpS05Xm" />
          <br />
          <button type="submit" className="button button--large button--primary">Send</button>
          <button type="reset" className="button button--large button--secondary">Clear</button>
          </form>
     </div>
      <div className="double-block">
  
                {/* Facebook Timeline */}
      {/* <div className="fb-page" data-href="https://www.facebook.com/cargroomer/" data-tabs="timeline" data-width="450" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/cargroomer/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/cargroomer/">Eco Wash Solutions &amp; Detail</a></blockquote></div> */}
      {/* End Facedbook */}
      <p>Check out our other websites: <br /> <a href="https://ecocarwash.co.nz" className="styled-link" target="_blank" rel="noreferrer">www.ecocarwash.co.nz</a> <br /> <a href="https://ecotruckwash.co.nz" className="styled-link" target="_blank" rel="noreferrer">www.ecotruckwash.co.nz</a></p>
      </div>
   </Double>  
   {/* <div className="imageBg-block container--centered" id="gift">
     <div className="container">
     <h2 className="heading align--center">Give the gift of a sparkling car grooming</h2>
     <br />
     <h6 className="sub-heading sub-heading--basic align--center">What our clients say...</h6>
  
     </div>
  </div>  */}
  </Layout>
  <Footer copyright="Copyright &copy; 2022 Eco Wash Solutions. All Rights Reserved" />
  {/* <FloatingNotice>
    <h5>Covid 19 notice</h5>
    <p>All vehicle cleaning services now includes sanitizing before and after your car groom to protect you and our groomers.</p>
  </FloatingNotice> */}
  
  </>
}
